import React from 'react'
import './App.css'
import { Box, ThemeProvider, Typography } from '@mui/material'
import theme from './theme'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box id="bubbles" sx={{
        width: "100vw",
        height: "100vh",
      }}>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <Box
          sx={{
            position: "absolute",
            top: {
              xs: "16px",
              md: "30%",
            },
            left: "10%",
            display: "flex",
            flexDirection: "column",
            gap: "48px",
            width: {
              xs: "80vw",
              s: "80vw",
              md: "45%"
            }
          }}
        >
          <Box 
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap="12px"
            >
            <Box
              sx={{
                width: {
                  xs: "48px",
                  sm: "76px"
                }
              }}
            >
              <img height="100%" width="100%" src="/chrono-logo.png" className="Chronotracer logo" alt="logo" />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="4px"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap="0px"
              >
                <Typography 
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "18px",
                      sm: "36px"
                    },
                    lineHeight: {
                      xs: "18px",
                      sm: "36px"
                    }
                  }}
                >
                  CHRONOTRACER
                </Typography>
                <Typography 
                  sx={{
                    fontSize: {
                      xs: "12px",
                      sm: "14px"
                    }, 
                    lineHeight: {
                      xs: "12px",
                      sm: "14px"
                    }
                  }}
                  id="trademark">
                  TM
                </Typography>
              </Box>
              <Typography variant="body1">Your evidentiary super-power</Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gap="24px">
            <Typography variant="body1">
              ChronoTracer is a new, cloud-based software service from Quoyi LLC that empowers litigators and investigators to analyze electronic evidence. When you’re digging in to emails, phone records, text messages, photos, videos, financial records, voicemails, or forensic logs, we can help you to figure out the narrative of who did what with whom and when - quickly, efficiently and intuitively.
            </Typography>
            <Typography variant="body1">
              We are working with a select group of clients to super-charge their evidence reviews. If you’d like more information, a demonstration or a proposal, please contact us.
            </Typography>
            <button 
              id="fade-3" style={{
                padding: "12px 24px",
                backgroundColor: "#0071B0",
                border: "none",
                width: "fit-content",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                window.location.href = "mailto:info@quoyi.com"
              }}
            >
              <Typography variant="h3">Contact Us</Typography>
            </button>
          </Box>
        </Box>
        <Box sx={{
          position: "fixed",
          bottom: "16px",
          height: "16px",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        >
          <Typography variant="body1" sx={{color: "#ccc"}}>© {new Date().getFullYear()} Quoyi LLC</Typography>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App;
