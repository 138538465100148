import { createTheme } from "@mui/material"

declare module "@mui/material/styles" {
  interface Palette {
    fileInput: Palette["primary"]
    disabled?: Palette["primary"]
    avatar?: Palette["primary"]
    hint?: Palette["primary"]
    asset?: Palette["primary"]
  }
  interface PaletteOptions {
    fileInput: PaletteOptions["primary"]
    disabled?: PaletteOptions["primary"]
    avatar?: PaletteOptions["primary"]
    hint?: PaletteOptions["primary"]
    asset?: PaletteOptions["primary"]
  }
}

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    h1: {
      fontSize: "36px",
      color: "#2F384C",
      fontWeight: "bold",
      lineHeight: "36px"
    },
    h2: {
      fontSize: "24px",
      color: "#2F384C",
      fontWeight: "bold",
      lineHeight: "24px"
    },
    h3: {
      fontSize: "18px",
      color: "white",
      fontWeight: "bold",
      lineHeight: "24px"
    },
    body1: {
      fontSize: "16px",
      color: "#2F384C",
      lineHeight: "24px",
      fontWeight: 500,
    },
    body2: {
      fontSize: "14px",
      color: "#2F384C",
      fontWeight: 500,
    },
  },
  palette: {
    primary: {
      main: "#2F384C",
    },
    text: {
      primary: "#2F384C",
    },
    error: {
      main: "#D80B00",
      dark: "#B70900"
    },
    success: {
      main: "#C8ECE7",
      light: "#03423A",
      dark: "#17564E"
    },
    fileInput: {
      main: "#E7FBFF",
      light: "#63636C",
      dark: "#28282F"
    },
    disabled: {
      main: "rgba(0, 0, 0, 0.26)"
    },
    avatar: {
      main: "#000030"
    },
    hint: {
      main: "#3AB6CE",
      dark: "#5E3F08",
      contrastText: "#FFE8B0"
    },
    asset: {
      main: "#0C0C0D"
    }
  },
})

export default theme
